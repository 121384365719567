import { defineStore } from "pinia";
import { Header } from "@/types/header";
import { UserPasswordType } from "@/types/user_password";
import { API_URL } from "@/utils/urls";


export const useMainStore = defineStore("mainStore", {
  actions: {
    async getToken(user_password_obj: UserPasswordType): Promise<any> {
      return await this.postOnAPI(
        `${API_URL}/auth/login/`, 
        user_password_obj, 
        {"Content-Type": "application/json"}
      )
    },
    async getEmployee(username: string) {
      return await this.getFromAPI(`${API_URL}/users/report/${username}`)
    },
    async isUserAllowed(data: any): Promise<boolean> {
      let response = await this.getToken(data)
      const token = response.key
      
      window.localStorage.setItem("Token", token);
      response = await this.getEmployee(data.username);
      window.localStorage.setItem("user_pk", response[0].user);
      if (response[0].role !== "pecsmart_admin") throw new Error('User Not Allowed')
      return true
    },
    async requestFromAPI(url: string, init: RequestInit): Promise<any> {
      return await fetch(url, init)
      .then((response) => {
        if (response.statusText === "No Content") return response
        if (response.ok) return response.json();
        throw response
      })
      .then(json => {
        return json
      })
      .catch(async (error: any) => {
        const response_body = await error.body?.getReader().read()
        throw new TextDecoder().decode(response_body?.value)
      });
    },
    async getFromAPI(url: string): Promise<any> {
      return await this.requestFromAPI(url, {headers: this.getHeader})
    },
    async getAllFromAPI(url: string, aggregatedResults: any[] = [], next_field_present = true, n_page = 1): Promise<any[]> {
      const response = await this.getFromAPI(`${url}${next_field_present ? '' : '&page=' + n_page}`);
      const newResults = [...aggregatedResults, ...response.results];

      if (next_field_present) {
        if (response.next) {
          // If 'next' field is available, use it
          const httpsNext = response.next.replace("http:", "https:");
          return await this.getAllFromAPI(httpsNext, newResults);
        }
      } else {
        if (response.results.length === 200) {
          // If 'next' field is not present, and 200 results are returned, proceed to next page manually
          return await this.getAllFromAPI(url, newResults, false, n_page + 1);
        }
      }

      return newResults;
    },
    async postOnAPI(url: string, data: any, header?: Header): Promise<any> {
      return await this.requestFromAPI(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header !== undefined ? header : this.getHeader,
      })
    },
    async deleteFromAPI(url: string): Promise<any> {
      return await this.requestFromAPI(url, {method: "DELETE", headers: this.getHeader})
    },
    checkUserPK(): string {
      const user_pk: string | null = window.localStorage.getItem("user_pk")
      if (user_pk == null) throw "No user pk, Refresh page"
      return user_pk
    }
  },
  getters: {
    getHeader(): Header {
      return {
        "Content-Type": "application/json",
        "Authorization": `Token ${window.localStorage.getItem("Token")}`,
      };
    },
  },
});
