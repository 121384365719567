import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    props: true,
    component: Login,
  },
  {
    path: '/platform',
    name: 'Platform',
    props: true,
    component: () => import('../views/Platform.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        props: true,
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: 'sensor-logs/:farm_pk',
        name: 'SensorLog',
        props: true,
        component: () => import('../views/SensorLog.vue'),
      },
      {
        path: 'maintenance',
        name: 'Maintenance',
        props: true,
        component: () => import('../views/Maintenance.vue'),
        redirect: {
          name: 'CamMaintenance'
        },
        children: [
          {
            path: 'smartcam',
            name: 'CamMaintenance',
            component: () => import('../components/CamMaintenance.vue'),
          },
          {
            path: 'smartmic',
            name: 'MicMaintenance',
            component: () => import('../components/MicMaintenance.vue'),
          },
          {
            path: 'smartfeed',
            name: 'FeedMaintenance',
            component: () => import('../components/FeedMaintenance.vue'),
          },
        ],
      },
      {
        path: 'register',
        name: 'Registration',
        props: true,
        component: () => import('../views/Registration.vue'),
        redirect: {
          name: 'RegisterUser'
        },
        children: [
          {
            path: 'user',
            name: 'RegisterUser',
            props: true,
            component: () => import('../components/RegisterUser.vue')
          },
          {
            path: 'company',
            name: 'RegisterCompany',
            props: true,
            component: () => import('../components/RegisterCompany.vue')
          },
          {
            path: 'farm',
            name: 'RegisterFarm',
            props: true,
            component: () => import('../components/RegisterFarm.vue')
          },
          {
            path: 'barn',
            name: 'RegisterBarn',
            props: true,
            component: () => import('../components/RegisterBarn.vue')
          },
          {
            path: 'silo',
            name: 'RegisterSilo',
            props: true,
            component: () => import('../components/RegisterSilo.vue')
          },
          {
            path: 'food',
            name: 'RegisterFood',
            props: true,
            component: () => import('../components/RegisterFood.vue')
          },
          {
            path: 'smartcam',
            name: 'RegisterSmartCam',
            props: true,
            component: () => import('../components/RegisterSmartCam.vue')
          },
          {
            path: 'smartmic',
            name: 'RegisterSmartMic',
            props: true,
            component: () => import('../components/RegisterSmartMic.vue')
          },
          {
            path: 'smartfeed',
            name: 'RegisterSmartFeed',
            props: true,
            component: () => import('../components/RegisterSmartFeed.vue')
          },
          {
            path: 'stall',
            name: 'RegisterStall',
            props: true,
            component: () => import('../components/RegisterStall.vue')
          },
          {
            path: 'batch',
            name: 'RegisterBatch',
            props: true,
            component: () => import('../components/RegisterBatch.vue')
          },
          {
            path: 'reference-weight-curve',
            name: 'RegisterReferenceWeightCurve',
            props: true,
            component: () => import('../components/RegisterReferenceWeightCurve.vue')
          },
        ]
      },
      {
        path: 'installation',
        name: 'Installation',
        props: true,
        component: () => import('../views/Installation.vue'),
        redirect: {
          name: 'InstallSmartCam'
        },
        children: [
          {
            path: 'smartcam',
            name: 'InstallSmartCam',
            component: () => import('../components/InstallSmartCam.vue'),
          },
          {
            path: 'smartmic',
            name: 'InstallSmartMic',
            component: () => import('../components/InstallSmartMic.vue'),
          },
          {
            path: 'smartfeed',
            name: 'InstallSmartFeed',
            component: () => import('../components/InstallSmartFeed.vue'),
          },
        ],
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
